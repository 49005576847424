
import {get, postJ} from "@/request";
import {IPage} from "@/apis/page";
import {IBannerCreate, IBannerList, IDict} from "@/apis/decorate/types";


/**
 * 背景图-创建
 * @param param
 */
export const dictSetUpApi = (param: IDict) => get("/mall/dict/setUp", param)

/**
 * 背景图-详情
 * @param param
 */
export const dictDetailApi = (types:number) => get("/mall/dict/detail", {types})


/**
 * 轮播图-列表查询
 * @param param
 */
export const bannerListApi = (param: IPage) => get("/mall/banner/list", param)

/**
 * 轮播图-创建
 * @param param
 */
export const bannerCreateApi = (data: IBannerCreate) => postJ<IBannerList[]>("/mall/banner/create", data)

/**
 * 轮播图-修改
 * @param param
 */
export const bannerModifyApi = (data: IBannerCreate) => postJ<IBannerList[]>("/mall/banner/modify", data)


/**
 * 轮播图-删除
 * @param id
 */
export const bannerRemoveApi = (id: string) => get("/mall/banner/remove/" + id)

/**
 * 轮播图-切换状态
 * @param id
 */
export const bannerSetUpApi = (id: string) => get("/mall/banner/setUp/" + id)

/**
 * 轮播图-详情
 * @param id
 */
export const bannerDetailApi = (id: string) => get("/mall/banner/detail/" + id)

/**
 * 活动专区-列表查询
 * @param param
 */
export const activityRegionQueryApi = (param: IPage) => get("/mall/activityRegion/query", param)

/**
 * 活动专区-切换状态
 * @param id
 */
export const activityRegionSetUpStatusApi = (id: string) => get("/mall/activityRegion/setUpStatus/" + id)

/**
 * 活动专区-创建
 * @param param
 */
export const activityRegionCreateApi = (data: any[]) => postJ<IBannerList[]>("/mall/activityRegion/create", data)
