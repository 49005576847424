
/**
 * @name: bgImg
 * @author: Gzm
 * @date: 2023-07-19 11:48
 * @description：商城装饰-背景图管理
 * @update: 2023-07-19 11:48
 */
import {Component, Vue, } from "vue-property-decorator";
import PictureUpload from "@/mui/components/PictureUpload";
import {IConfig} from "@/config/types";
import config from "@/config";
import {dictDetailApi, dictSetUpApi} from "@/apis/decorate";

@Component({
  components: {
    PictureUpload
  }
})

export default class ProductPage extends Vue {
  config: IConfig = config
  logoPic = ""

  //上传前检验
  beforeUpload(file: File): boolean {
    const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === "image/gif";
    const isLt2M = file.size / 1024 / 1024 < 1 ;

    if (!isJPG) {
      this.$message.error('上传图片只能是JPG、JPEG、GIF、PNG格式!');
    }
    if (!isLt2M) {
      this.$message.error('上传图片大小不能超过 1MB!');
    }
    return isJPG && isLt2M;
  }

  onUploadSuccess(res:any,file:any){
    this.logoPic = res.data.url
    dictSetUpApi({
      types:1,
      values:res.data.url
    }).then(e => {
      this.$message.success('上传成功')
      this.getData();
    })
  }

  getData(){
    dictDetailApi(1).then(e => {
      console.log(e)
      if (e){
        this.logoPic = e.value
      }
    })
  }

  created() {
    this.getData();
  }
}
